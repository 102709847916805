.card {
  border-radius: var(--size-radius-l);
}

.none {
  composes: card;
}

.s {
  composes: card;
  padding: var(--size-padding-s);
}

.m {
  composes: card;
  padding: var(--size-padding-m);
}

.l {
  composes: card;
  padding: var(--size-padding-l);
}

.xl {
  composes: card;
  padding: var(--size-padding-xl);
}

.xxl {
  composes: card;
  padding: var(--size-padding-xxl);
}

.light {
  background-color: var(--color-background-1);
}

.primary {
  color: var(--color-content-inverse);
  background-color: var(--color-content-primary);
}

.secondary {
  color: var(--color-content-inverse);
  background-color: var(--color-content-secondary);
}

.header {
  display: flex;
  margin-bottom: var(--size-4);
}

.title {
  padding: var(--size-padding-xs) var(--size-padding-s);
  height: 28px;
  color: var(--color-content-2);
  border-radius: var(--size-radius-m);
}

.options {
  padding: var(--size-padding-xs) var(--size-padding-s);
  width: 36px;
  height: 28px;
  border-radius: var(--size-radius-m);
  color: var(--color-content-2);
  background-color: var(--color-background-2);
  cursor: pointer;
}
