.nodeData {
  margin-bottom: 4px;
  margin-left: 8px;
  display: flex;
  justify-content: space-between;
  width: calc(50% - 24px);
  font: var(--text-body-s);
  color: var(--color-content-6);
}

.translation {
  display: flex;
  gap: 16px;
}

.translation > div {
  flex: 1;
  max-height: 100px;
  min-height: 100px;
  overflow: hidden;
}

.original {
  border-radius: var(--size-radius-l);
  border: 1px solid #f0f0f0;
  background-color: #f0f0f0;
}

.alternative {
  display: flex;
}

.input {
  flex: 1;
  border-radius: var(--size-radius-l);
  border: 1px solid #f0f0f0;
  overflow: hidden;
  background-color: #f0f0f0;
}

.invalidInput {
  composes: input;
  box-shadow: inset rgba(237, 53, 53, 0.75) 0 0 0 3px;
}

.adding {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--color-content-accent-light);
}

.actions {
  display: flex;
  flex-direction: column;
  width: 20px;
  flex-shrink: 0;
}

.adder {
  all: unset;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.adder > div {
  height: var(--size-action);
  padding: 0 var(--size-padding-l);
  font: var(--text-body-m);
  line-height: var(--size-action);
  border: 1px solid currentColor;
  border-radius: var(--size-radius-s);
  color: var(--color-content-accent-dark);
}

.adder:hover > div,
.adder:focus > div {
  color: var(--color-content-inverse);
  background-color: var(--color-content-accent-light);
}

.action {
  all: unset;
  display: block;
  width: 20px;
  height: 20px;
  overflow: hidden;
  text-indent: 1000px;
  white-space: nowrap;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center;
  cursor: pointer;
}

.deleteAction {
  composes: action;
  background-image: url('./trash.svg');
}

.validateAction {
  composes: action;
  background-image: url('./check.svg');
}

.saving {
  padding: 4px;
}

.saving > span {
  width: 100%;
  height: 100%;
  display: block;
}
