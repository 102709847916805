.illustration {
  width: 100%;
  margin-top: 12%;
  margin-left: 6%;
}

.formWrapper {
  max-width: 400px;
  margin: var(--size-4);
}

.sentence {
  margin-bottom: var(--size-9);
  font-weight: 600;
  font-size: 48px;
  line-height: 54px;
}

.sentence > span {
  font-style: italic;
  color: var(--color-content-accent);
}

.backToLogin {
  margin-top: 24px;
  font: var(--text-body-xs);
}
