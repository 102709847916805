.root {
  display: flex;
  align-items: center;
  padding: var(--size-padding-xs) var(--size-padding-s);
  border-radius: var(--size-radius-s);
  white-space: nowrap;
}

.medium {
  composes: root;
  height: 28px;
  font: var(--text-body-m);
}

.small {
  composes: root;
  height: 24px;
  font: var(--text-body-xs);
}

.light {
  color: var(--color-content-6);
  background-color: var(--color-background-2);
}

.pink {
  color: var(--color-content-6);
  background-color: #fae2fd;
}
