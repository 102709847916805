.row {
  display: block;
}

.row + .row {
  margin-top: 16px;
}

.label {
  display: block;
  font: var(--text-body-s);
  width: 100%;
  margin: 0 0 4px 0;
  cursor: pointer;
}

.error {
  font: normal normal 400 14px/20px Suisseintl, sans-serif;
  margin: 4px 0 0 0;
  color: var(--color-content-2);
}
