.root {
  padding: 2px;
  border-radius: 50%;
}

.root:disabled {
  cursor: default;
  color: rgb(108, 108, 108);
}

.root:not(:disabled):hover {
  background-color: rgb(228, 228, 228);
}

.root > svg {
  display: block;
}
