.node {
  border-radius: var(--size-radius-l);
  overflow: hidden;
}

.image {
  position: relative;
  height: 200px;
  padding: var(--size-padding-m);
  background: repeating-conic-gradient(
      var(--color-border-1) 0%,
      var(--color-border-1) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center / 20px 20px;
}

.image > img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.hiddenInput {
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  left: 0;
  top: 0;
  cursor: default;
  outline: none;
}

.change {
  position: absolute;
  top: 12px;
  right: 12px;
  z-index: 10;
}

.alt {
  padding: var(--size-padding-m);
  border-top: 1px solid #f0f0f0;
  background-color: #ffffff;
}
