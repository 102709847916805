.root {
  display: inline-block;
  animation: 1.4s linear 0s infinite normal none running root;
}

.small {
  width: 24px;
  height: 24px;
}

.medium {
  width: 32px;
  height: 32px;
}

.big {
  width: 42px;
  height: 42px;
}

@keyframes root {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.circle {
  stroke: currentColor;
  stroke-dasharray: 80px, 200px;
  stroke-dashoffset: 0;
  animation: circle 1.4s ease-in-out infinite;
}

@keyframes circle {
  0% {
    stroke-dasharray: 1px, 200px;
    stroke-dashoffset: 0;
  }
  50% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -15px;
  }
  100% {
    stroke-dasharray: 100px, 200px;
    stroke-dashoffset: -125px;
  }
}
