.root {
  display: flex;
  align-items: center;
  gap: 8px;
}

.root > label {
  user-select: none;
}

.wrapper {
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--size-padding-s);
  border-radius: var(--size-radius-s);
  border: 1px solid #e8e8e8;
}

.switch {
  width: 42px;
  height: 25px;
  border-radius: 9999px;
  position: relative;
  background-color: rgb(164, 164, 164);
}

/* .switch:focus {
  box-shadow: 0 0 0 2px black;
} */

.switch[data-state='checked'] {
  background-color: var(--color-content-accent);
}

.thumb {
  display: block;
  width: 21px;
  height: 21px;
  border-radius: 9999px;
  background-color: white;
  box-shadow: 0 2px 2px gray;
  transition: transform 100ms;
  transform: translateX(2px);
  will-change: transform;
}

.thumb[data-state='checked'] {
  transform: translateX(19px);
}

.state {
  width: 32px;
}
