/* Fix for Radix Popper to make sure it is above */
[data-radix-popper-content-wrapper] {
  z-index: 999 !important;
}

/* Changed position of HubSpot support action button */
body #hubspot-messages-iframe-container.widget-align-right {
  right: -6px !important;
}
body #hubspot-messages-iframe-container {
  bottom: 50px !important;
}
