:root,
.light {
  --color-content-1: rgba(0, 0, 0, 1);
  --color-content-2: rgba(0, 0, 0, 0.5);
  --color-content-3: rgba(0, 0, 0, 0.25);
  --color-content-4: rgba(47, 47, 47, 1);
  --color-content-5: rgba(86, 86, 86, 1);
  --color-content-6: rgba(102, 102, 102, 1);
  --color-content-7: rgba(126, 126, 126, 1);
  --color-content-8: rgba(157, 157, 157, 1);
  --color-content-primary: rgba(0, 211, 127, 1);
  --color-content-primary-dark: rgb(3, 177, 108);
  --color-content-primary-light: rgba(156, 247, 197, 1);
  --color-content-secondary: rgba(44, 97, 243, 1);
  --color-content-accent: rgba(125, 43, 216, 1);
  --color-content-accent-light: rgba(151, 85, 223, 1);
  --color-content-accent-dark: rgba(94, 22, 176, 1);
  --color-content-inverse: rgba(255, 255, 255, 1);
  --color-content-success: rgba(0, 84, 51, 1);
  --color-content-success-light: rgba(204, 221, 214, 1);
  --color-content-warning: rgba(237, 154, 56, 1);
  --color-content-warning-light: rgba(250, 225, 195, 1);
  --color-content-error: rgba(230, 73, 38, 1);
  --color-content-error-light: rgba(247, 200, 190, 1);
  --color-content-error-dark: rgba(189, 45, 13, 1);
  --color-content-info: rgba(237, 158, 247, 1);
  --color-content-info-light: rgba(250, 226, 253, 1);

  --color-surface-1: rgba(240, 240, 240, 1);
  --color-surface-2: rgba(229, 229, 229, 1);
  --color-surface-3: rgba(209, 209, 209, 1);
  --color-surface-4: rgba(189, 189, 189, 1);
  --color-surface-inverse: rgba(0, 0, 0, 1);
  --color-surface-danger: rgba(229, 26, 26, 0.2);

  --color-border-1: rgb(221, 221, 221);
  --color-border-2: rgb(196, 196, 196);

  --color-background-1: rgba(255, 255, 255, 1);
  --color-background-2: rgba(240, 240, 240, 1);
  --color-background-3: rgb(225, 225, 225, 1);

  --color-gradient-a: rgba(255, 255, 255, 1);
  --color-gradient-b: rgba(255, 255, 255, 0);
}

.dark {
  --color-content-1: rgba(255, 255, 255, 1);
  --color-content-2: rgba(255, 255, 255, 0.5);
  --color-content-3: rgba(255, 255, 255, 0.25);
  --color-content-4: rgb(150, 150, 150);
  --color-content-5: rgb(125, 125, 125);
  --color-content-6: rgb(100, 100, 100);
  --color-content-7: rgba(75, 75, 75, 1);
  --color-content-8: rgba(50, 50, 50, 1);
  --color-content-primary: rgba(0, 211, 127, 1);
  --color-content-primary-dark: rgb(3, 177, 108);
  --color-content-primary-light: rgba(156, 247, 197, 1);
  --color-content-secondary: rgba(44, 97, 243, 1);
  --color-content-accent: rgba(125, 43, 216, 1);
  --color-content-accent-light: rgb(142, 66, 228);
  --color-content-accent-dark: rgba(94, 22, 176, 1);
  --color-content-inverse: rgba(0, 0, 0, 1);
  --color-content-success: rgba(0, 84, 51, 1);
  --color-content-success-light: rgba(204, 221, 214, 1);
  --color-content-warning: rgba(237, 154, 56, 1);
  --color-content-warning-light: rgba(250, 225, 195, 1);
  --color-content-error: rgba(230, 73, 38, 1);
  --color-content-error-light: rgba(247, 200, 190, 1);
  --color-content-error-dark: rgba(189, 45, 13, 1);
  --color-content-info: rgba(237, 158, 247, 1);
  --color-content-info-light: rgba(250, 226, 253, 1);

  --color-surface-1: rgba(255, 255, 255, 0.15);
  --color-surface-2: rgba(255, 255, 255, 0.2);
  --color-surface-3: rgba(255, 255, 255, 0.25);
  --color-surface-4: rgba(255, 255, 255, 0.3);
  --color-surface-inverse: rgba(255, 255, 255, 1);
  --color-surface-danger: rgba(229, 26, 26, 0.3);

  --color-border-1: rgb(47, 47, 47);
  --color-border-2: rgb(99, 99, 99);

  --color-background-1: rgba(0, 0, 0, 1);
  --color-background-2: rgba(24, 24, 24, 1);
  --color-background-3: rgba(47, 47, 47, 1);

  --color-gradient-a: rgba(202, 152, 251, 1);
  --color-gradient-b: rgba(253, 142, 114, 1);
  --color-gradient-fallback: rgba(222, 149, 199, 1);
}

:root {
  background-color: rgba(255, 255, 255, 1);
}

:root.dark {
  background-color: rgba(0, 0, 0, 1);
}
