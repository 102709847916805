.toast {
  display: flex;
  padding: var(--size-padding-m);
  position: relative;
  border-radius: var(--size-radius-m);
  border-left-width: 8px;
  border-left-style: solid;
}

.toast > i {
  margin-right: 12px;
}

.toast > i > svg {
  display: block;
  width: 20px;
}

.warning {
  background-color: rgba(250, 225, 195, 1);
  border-color: #ed9a38;
}

.success {
  background-color: var(--color-content-success-light);
  border-color: var(--color-content-success);
}

.info {
  background-color: rgba(250, 226, 253, 1);
  border-color: var(--color-content-info);
}

.content {
  margin-right: 24px;
}

.small {
  font: var(--text-body-s);
}

.close {
  position: absolute;
  right: 5px;
  top: 5px;
}

.top {
  margin-top: 16px;
}

.bottom {
  margin-bottom: 16px;
}

.both {
  margin-top: 16px;
  margin-bottom: 16px;
}

.minimal {
  color: var(--color-content-5);
  background-color: transparent;
  border-color: transparent;
}
