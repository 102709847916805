.wrapper {
  position: relative;
}

.measure {
  position: absolute;
  top: 0;
  right: 0;
  left: 0;
  pointer-events: none;
  font: var(--text-body-m);
  white-space: break-spaces;
  visibility: hidden;
}

.input {
  all: unset;
  display: block;
  width: 100%;
  font: var(--text-body-m);
  resize: none;
}

.input[aria-invalid='true'] {
  background-color: var(--color-surface-danger);
}

.input::placeholder {
  font: var(--text-body-m);
  color: var(--color-content-2);
}

.input:disabled,
.input:disabled::placeholder {
  color: var(--color-content-3);
}

.input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.fullHeight {
  height: 100%;
}
