.root {
  display: flex;
  margin-bottom: 24px;
  align-items: center;
  justify-content: center;
  border-radius: 28px;
  background-color: #e6e6e6;
}

.item {
  min-width: 160px;
  padding: var(--size-padding-s) var(--size-padding-l);
  margin-top: 4px;
  margin-bottom: 4px;
  text-align: center;
  text-decoration: none;
  border-radius: 24px;
  color: rgb(26, 31, 34);
}

.item.active {
  background-color: #fff;
}

/* Previous tabs */

/* .tabs {
  display: flex;
  align-items: center;
  margin-bottom: 24px;
  border-bottom: 2px solid #f0f0f0;
}

.tabsCenter {
  composes: tabs;
  justify-content: center;
}

.tabsLink {
  min-width: 160px;
  padding: var(--size-padding-m) var(--size-padding-xl);
  margin-bottom: -2px;
  text-decoration: none;
  text-align: center;
  border-bottom-width: 2px;
  border-bottom-style: solid;
  border-bottom-color: transparent;
}

.tabsLink.active {
  font-weight: 500;
  border-bottom-color: var(--color-content-accent);
}

.tabsLink.active2 {
  border-bottom-color: var(--color-content-accent);
  background-color: rgba(125, 43, 216, 0.1);
} */
