.help {
  padding: 8px 20px;
  border-radius: 8px;
  border: 1px solid rgba(0,0,0,0.1);
  font-size: 16px;
  font-weight: 600;
  text-align: center;
  margin-bottom: 20px;
  text-decoration:none;
  color: rgba(0,0,0,0.80);
  transition: all 0.2s ease-in;
}

.help:hover {
  color: #7d2bd8;
  border-color: #7d2bd8;
}

.trigger {
  background-image: linear-gradient(318.72deg,#00d37f 31.4%,#ccf9e7 178.48%),linear-gradient(316.92deg,#00d37f 15.62%,#ccf9e7 172.1%);
  bottom: 30px;
  position: fixed;
  right: 30px;
  z-index: 994;
  border-radius: 50%;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
}

.icon {
  background-position: center;
  border-radius: 50%;
  box-shadow: 0 5px 15px rgb(0 0 0 / 5%);
  cursor: pointer;
  height: 60px;
  width: 60px;
  outline: none;
}

.icon > img {
  max-width: 60px;
}

.container {
  right: 20px;
  position: fixed;
  bottom: 100px;
  width: 324px;
  z-index: 998;
  transition: opacity 250ms ease-out, transform 150ms ease-out;
}

.hiddenContainer {
  composes: container;
  opacity: 0;
  pointer-events: none;
  transform: translateY(5%);
}

.video > span {
  font-weight: 600;
  margin-bottom: 20px;
  display: block;
}

.video {
  margin-bottom: 12px;
}

.video iframe {
  width: 100%;
  height: 144px !important;
}

.menu {
  margin: 0;
  padding: 0;
}

.number {
  width: 24px;
  height: 24px;
  content: '';
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(0,0,0,0.1);
  color: rgba(0,0,0,0.54);
  font-size: 13px;
  line-height: 24px;
  border-radius: 100%;
  margin-right: 12px;
  transition: all 0.2s ease-in;
}

.innerContainer {
  padding: 20px;
  box-shadow: 0 2px 6px -1px rgb(30 30 49 / 20%), 0 24px 60px -12px rgb(30 30 49 / 18%), 0 6px 16px rgb(30 30 49 / 8%);
  border-radius: 8px;
  overflow: hidden;
  background: white;
}

.hiddenInnerContainer {
  display: none;
}

.innerContainer iframe {
  height: 420px;
  width: 100%;
  border: 1px solid lightgrey;
  border-radius: 4px;
}

.link {
  color: rgba(0,0,0,0.80);
  cursor: pointer;
  padding: 10px 0;
  text-align: left;
  width: 100%;
  text-decoration: none;
  font-size: 17px;
  line-height: 24px;
  display: flex;
  align-items: center;
  transition: all 0.2s ease-in;
}

.link:hover{
  color: #7d2bd8;
}

.link:hover .number {
  color: white;
  background: #7d2bd8;
}

.chat {
  composes: help;
  cursor: pointer;
}

.close {
  composes: help;
  display: block;
  color: rgba(0,0,0,0.80);
  cursor: pointer;
  text-align: left;
  width: inherit;
  text-decoration: none;
  font-size: 17px;
  line-height: 24px;
  align-items: center;
  background-color: #fff;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 20px;
}