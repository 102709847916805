.node {
  padding: var(--size-padding-s) var(--size-padding-m);
  display: block;
  font: var(--text-body-m);
  color: #666666;
}

.node > * + * {
  padding-top: 8px;
}

.node > :first-child {
  padding-top: 0 !important;
}

.node > :last-child {
  margin-bottom: 0 !important;
}

.node h1 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-m);
}

.node h2 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-s);
}

.node h3 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-xs);
}

.node h4 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-body-m-bold);
}

.node strong,
.node b {
  font-weight: 600;
}

.node em,
.node i {
  font-style: italic;
}

.node img {
  max-width: 100%;
}
