.container {
  min-width: 320px;
  overflow: hidden;
  border-radius: 8px;
}

.head {
  display: flex;
  justify-content: space-between;
  padding: 12px;
  background-color: #eaeaf1;
}

.externalLink > svg {
  width: 14px;
  height: 14px;
  color: var(--color-content-accent);
}

.body {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 16px;
  gap: 16px;
}

.bodyBuild {
  composes: body;
  min-height: 120px;
}

.bodyBuild > label {
  font: var(--text-body-xs);
}

.bodyBuild > span {
  color: var(--color-content-primary);
}

.bodyBuild > p {
  font: var(--text-body-s);
  color: var(--color-content-6);
}

.link {
  display: block;
  margin: auto;
  text-align: center;
  max-width: 260px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font: var(--text-body-s);
  color: var(--color-content-accent);
}

.body .error {
  max-width: 250px;
  margin: auto;
  text-align: center;
  font: var(--text-body-s);
  color: var(--color-content-error);
}

.success > p {
  display: flex;
  height: 40px;
  align-items: center;
  gap: 8px;
  color: var(--color-content-success);
}

.success > p > svg {
  width: 25px;
}
