.input {
  display: block;
  box-sizing: border-box;
  padding: 0 var(--size-padding-l);
  margin: 0;
  border: none;
  outline: none;
  font: var(--text-body-m);
  color: var(--color-content-1);
  border-radius: var(--size-radius-s);
  background-color: var(--color-surface-1);
  transition: color var(--time-1) ease-out,
    background-color var(--time-1) ease-out;
}

.small {
  composes: input;
  height: var(--size-action-small);
}

.medium {
  composes: input;
  height: var(--size-action);
}

.input[aria-invalid='true'] {
  background-color: var(--color-surface-danger);
}

.input::placeholder {
  font: var(--text-body-m);
  color: var(--color-content-2);
}

.input:disabled,
.input:disabled::placeholder {
  color: var(--color-content-3);
}

.input::-webkit-calendar-picker-indicator {
  opacity: 0;
}

.error {
  border: 2px solid var(--color-content-error);
  background-color: var(--color-content-error-light);
}
