.root {
  display: flex;
  align-items: center;
}

.locale {
  display: flex;
  align-items: center;
  flex-shrink: 0;
  box-sizing: border-box;
  height: var(--size-action);
  padding: var(--size-padding-s) var(--size-padding-l);
  margin: 0;
  border-width: 0 1px 0 0;
  border-style: solid;
  border-color: #666666;
  font: var(--text-body-m);
  color: #666666;
  border-radius: var(--size-radius-s) 0 0 var(--size-radius-s);
  background-color: var(--color-surface-1);
  transition: color var(--time-1) ease-out,
    background-color var(--time-1) ease-out;
}

.small {
  height: var(--size-action-small);
}

.input {
  flex: 1;
}

.locale + .input input {
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  border-left-width: 0 !important;
}
