.overlay {
  position: fixed;
  inset: 0;
  background-color: var(--color-content-2);
  z-index: 1000;
}

.content {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  width: 90vw;
  max-width: 500px;
  max-height: 85vh;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  border-radius: var(--size-radius-l);
  background-color: white;
  box-shadow: hsl(206 22% 7% / 35%) 0 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0 10px 20px -15px;
  z-index: 1001;
}

.contentPadding {
  padding: var(--size-padding-xl);
}

.m {
  composes: content;
  max-width: 500px;
}

.l {
  composes: content;
  max-width: 650px;
}

.xl {
  composes: content;
  max-width: 800px;
}

.xxl {
  composes: content;
  max-width: 950px;
}

.content:focus {
  outline: none;
}

@media (prefers-reduced-motion: no-preference) {
  .overlay {
    animation: overlayShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }

  .content {
    animation: contentShow 150ms cubic-bezier(0.16, 1, 0.3, 1) forwards;
  }
}

@keyframes overlayShow {
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
}

@keyframes contentShow {
  0% {
    opacity: 0;
    transform: translate(-50%, -48%) scale(0.96);
  }

  100% {
    opacity: 1;
    transform: translate(-50%, -50%) scale(1);
  }
}

.title {
  margin: 0;
  margin-bottom: 16px;
  font: var(--text-heading-xs);
}

.description {
  margin-bottom: 20px;
  font-size: 15px;
  line-height: 1.5;
}

.body {
  overflow-y: auto;
}

.bodyPadding {
  padding: var(--size-padding-xs);
}

.actions {
  display: flex;
  justify-content: flex-end;
  gap: 24px;
}

.close {
  position: absolute;
  top: 10px;
  right: 10px;
}
