.button {
  display: flex;
  justify-content: center;
  align-items: center;
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-decoration: none; /* For the anchor button */
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: 0 var(--size-padding-l);
  border-radius: var(--size-radius-s);
  margin: 0;
  border: none;
  transition: color var(--time-1) ease-out,
    background-color var(--time-1) ease-out;
  cursor: pointer;
  user-select: none;
  text-align: center;
}

.medium {
  height: var(--size-action);
  font: var(--text-body-m);
}

.small {
  height: var(--size-action-small);
  font: var(--text-body-m);
}

.button > span {
  display: inline-flex;
  align-items: center;
  gap: 9px;
}

.startIcon {
  display: flex;
  margin-right: 12px;
}

.endIcon {
  display: flex;
  margin-left: 12px;
}

/* STANDARD BUTTON */

.standard {
  composes: button;
  color: var(--color-content-5);
}

.contained.standard {
  background-color: var(--color-surface-1);
}

.contained.standard:not(:disabled):hover,
.contained.standard:active {
  background-color: var(--color-surface-2);
}

.text.standard:not(:disabled):hover,
.outlined.standard:not(:disabled):hover {
  background-color: var(--color-surface-3);
}

.outlined.standard {
  border-color: var(--color-border-2);
}

/* HIGHLIGHT BUTTON */

.highlight {
  composes: button;
}

.contained.highlight {
  color: var(--color-content-inverse);
  background-color: var(--color-content-accent);
}

.text.highlight,
.outlined.highlight {
  color: var(--color-content-accent-dark);
}

.contained.highlight:not(:disabled):hover,
.contained.highlight:active {
  background-color: var(--color-content-accent-dark);
}

.text.highlight:not(:disabled):hover,
.outlined.highlight:not(:disabled):hover {
  color: var(--color-content-inverse);
  background-color: var(--color-content-accent-light);
}

.outlined.highlight {
  border-color: var(--color-content-accent-dark);
}

/* SPECIAL */

.special {
  composes: button;
}

.contained.special {
  color: var(--color-content-inverse);
  background-color: var(--color-content-primary);
}

.text.special,
.outlined.special {
  color: var(--color-content-primary);
}

.contained.special:not(:disabled):hover,
.contained.special:active {
  background-color: var(--color-content-primary-dark);
}

.text.special:not(:disabled):hover,
.outlined.special:not(:disabled):hover {
  color: var(--color-content-primary-dark);
  background-color: var(--color-content-primary-light);
}

.outlined.special {
  color: var(--color-content-primary-dark);
  border-color: var(--color-content-primary);
}

/* DANGER BUTTON */

.danger {
  composes: button;
}

.contained.danger {
  color: var(--color-content-inverse);
  background-color: var(--color-content-error);
}

.text.danger,
.outlined.danger {
  color: var(--color-content-error);
}

.contained.danger:not(:disabled):hover,
.contained.danger:active {
  background-color: var(--color-content-error-dark);
}

.text.danger:not(:disabled):hover,
.outlined.danger:not(:disabled):hover {
  color: var(--color-content-inverse);
  background-color: var(--color-content-error);
}

.outlined.danger {
  border-color: var(--color-content-error);
}

/* DARK BUTTON */

.dark {
  composes: button;
}

.contained.dark {
  color: var(--color-content-inverse);
  background-color: var(--color-surface-inverse);
}

.text.dark,
.outlined.dark {
  color: var(--color-content-1);
}

/* .contained.dark:hover,
.contained.dark:active {
  background-color: var(    );
} */

.text.dark:not(:disabled):hover,
.outlined.dark:not(:disabled):hover {
  color: var(--color-content-inverse);
  background-color: var(--color-surface-inverse);
}

.outlined.dark {
  border-color: var(--color-surface-inverse);
}

/* ICON */

.icon {
  width: var(--size-action);
  padding: 0;
  flex-shrink: 0;
  color: var(--color-content-8);
}

.icon.small {
  width: var(--size-action-small);
}

/* SHARED */

.outlined,
.text {
  background-color: transparent;
}

.outlined {
  border-width: 1px;
  border-style: solid;
}

:is(.standard, .highlight, .danger):focus {
  outline: solid 2px var(--color-content-2);
}

/* DISABLED */

.button:disabled,
.button:disabled:hover,
.disabled {
  opacity: 0.45;
  cursor: default;
}

.button:disabled > i {
  opacity: 0.5;
}

.progress {
  position: absolute;
  display: flex;
  color: rgb(86 86 86 / 64%);
}
