.spinner {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
}

.bar {
  position: fixed;
  z-index: 20;
  bottom: 0;
  width: 100%;
  background-color: white;
  box-shadow: 0 -10px 16px rgb(0 0 0 / 6%);
}

.settings {
  max-width: 1200px;
  margin: auto;
  padding: var(--size-padding-m);
  padding-left: 32px;
  padding-right: 32px;
  display: flex;
  gap: 16px;
  justify-content: space-between;
}

.alert {
  padding: var(--size-padding-m);
  text-align: center;
  background-color: var(--color-content-warning-light);
}

.nonPaymentAlert {
  margin-left: 8px;
  font: var(--text-body-s);
}

.nonPaymentAlert > strong {
  font-weight: 500;
}

.nonPaymentAlert > a {
  text-decoration: none;
  color: var(--color-content-accent);
}

.siteName {
  font: var(--text-heading-xs);
}

.actions {
  display: flex;
  gap: 12px;
}

.warningsContainer {
  min-height: 120px;
  padding-top: 20px;
  padding-bottom: 20px;
}

.warningsGroup + .warningsGroup {
  margin-top: 16px;
}

.warningsGroup > p {
  display: flex;
  gap: 8px;
  margin-bottom: 4px;
}

.warningsGroup > ul {
  font-size: 14px;
  list-style: disc;
  margin-left: 16px;
}

.errorView {
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 16px;
}

.errorView > *:first-child {
  font-size: 24px;
}
