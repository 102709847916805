.root {
  display: flex;
  align-items: center;
  position: relative;
  padding-top: 3px;
  padding-bottom: 3px;
  padding-left: 8px;
  font: var(--text-body-s);
  border-width: 1px;
  border-radius: var(--size-radius-l);
  color: var(--color-content-1);
}

.close {
  right: 2px;
  position: absolute;
}
