.checkbox {
  display: flex;
  align-items: center;
  width: fit-content;
  outline: 0;
  cursor: pointer;
}

.input {
  visibility: hidden;
  position: absolute;
  pointer-events: none;
}

.indicator {
  width: 16px;
  height: 16px;
  display: flex;
  align-items: center;
  justify-content: center;
  border-width: 1px;
  border-style: solid;
  border-color: rgba(0, 0, 0, 0.1);
  background-color: rgba(0, 0, 0, 0.02);
  transition: background-color 250ms ease-out;
}

.checkbox:focus > .input:not(:disabled) + .indicator {
  border-color: var(--color-content-1);
}

.input:checked ~ .indicator {
  background: var(--color-content-2) url('../images/check.svg') no-repeat center;
  background-size: 12px;
}

.label {
  margin-left: 8px;
  user-select: none;
}

.disabled {
  opacity: 0.5;
  cursor: default;
}
