.items {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  grid-gap: 16px;
  max-height: 400px;
  overflow: auto;
}

.item {
  position: relative;
  height: 168px;
  border-radius: var(--size-radius-l);
  background: repeating-conic-gradient(
      var(--color-border-1) 0%,
      var(--color-border-1) 25%,
      transparent 0%,
      transparent 50%
    )
    50% center / 20px 20px;
  overflow: hidden;
}

.adder {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  background-color: var(--color-background-1);
  border: 1px dashed #666666;
  cursor: pointer;
  position: relative;
  border-radius: var(--size-radius-l);
}

.adder.disabled {
  cursor: unset;
}

.placeholder {
  white-space: nowrap;
  position: relative;
  overflow: hidden;
  text-overflow: ellipsis;
  box-sizing: border-box;
  padding: var(--size-padding-s) var(--size-padding-xl);
  border-radius: var(--size-radius-m);
  user-select: none;
  text-align: center;
  border: 1px solid #1a1f22;
  font: var(--text-body-m);
}

.placeholder.disabled {
  opacity: 0.5;
}

.adder input {
  opacity: 0;
  pointer-events: none;
  position: absolute;
}

.spinner {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
}

.actions {
  position: absolute;
  top: 0;
  right: 0;
  display: flex;
  align-items: center;
  gap: 8px;
  padding: var(--size-padding-s);
}

.action {
  all: unset;
  display: block;
  width: 32px;
  height: 32px;
  border-radius: 50%;
  overflow: hidden;
  white-space: nowrap;
  text-indent: 1000px;
  background-color: var(--color-background-1);
  background-repeat: no-repeat;
  background-position: center;
  background-size: 16px;
  cursor: pointer;
}

.delete {
  composes: action;
  background-image: url('../../ui/images/trash.svg');
}

.check {
  composes: action;
  pointer-events: none;
  background-image: url('../../ui/images/check.svg');
  background-color: var(--color-content-accent);
}

.preview {
  all: unset;
  display: block;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.preview img {
  display: block;
  width: 100%;
  height: 100%;
  object-fit: contain;
}

.buttons {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 16px;
  margin-top: 32px;
}
