.spinner {
  position: relative;
  aspect-ratio: 1;
  --color: var(--color-content-secondary);
}

.spinner::before,
.spinner::after {
  content: '';
  position: absolute;
  top: 50%;
  left: 50%;
  width: 100%;
  height: 100%;
  border-radius: 50%;
  transform: translate(-50%, -50%) scale(0);
}

.spinner::before {
  background: var(--color);
  animation: pulse 2s ease-in-out infinite;
}

.spinner::after {
  background: var(--color);
  animation: pulse 2s 1s ease-in-out infinite;
}

.s {
  composes: spinner;
  width: 24px;
}

.m {
  composes: spinner;
  width: 48px;
}

.l {
  composes: spinner;
  width: 64px;
}

@keyframes pulse {
  0%,
  100% {
    transform: translate(-50%, -50%) scale(0);
    opacity: 1;
  }
  50% {
    transform: translate(-50%, -50%) scale(1);
    opacity: 0;
  }
}
