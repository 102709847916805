.root {
  display: flex;
  flex-direction: column;
  height: 100vh;
  background-color: rgba(242, 242, 242, 1);
}

.header {
  display: flex;
  padding: var(--size-padding-l);
  justify-content: space-between;
  position: absolute;
  width: 100%;
  z-index: 100;
  pointer-events: none;
}

.header > div {
  pointer-events: auto;
}

.banner {
  padding: 12px;
  text-align: center;
  font: var(--text-body-s);
  color: #fff;
  background-color: var(--color-content-secondary);
}

.bannerOffset {
  top: 46px; /* Height of the banner with one line of text */
}

.upgradeLink {
  text-decoration: none;
  font: var(--text-body-s);
  font-weight: bold;
  color: var(--color-content-accent);
}

.box {
  display: flex;
  align-items: center;
  border-radius: var(--size-radius-s);
  background-color: #ffffff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.06);
}

.head {
  composes: box;
}

.head > div {
  padding: var(--size-padding-m) var(--size-padding-xl);
}

.headDivider {
  height: 100%;
  border-left: 1px solid rgba(232, 232, 232, 1);
}

.logo {
  display: flex;
  text-decoration: none;
}

/* .logo::after {
  content: 'BETA';
  margin-left: -2px;
  margin-top: -4px;
  font-size: 14px;
} */

.logo > svg {
  height: 30px;
  width: 120px;
}

.profile {
  display: flex;
  align-items: center;
  gap: 12px;
  cursor: pointer;
}

.avatar {
  display: block;
  width: 32px;
  aspect-ratio: 1;
  object-fit: cover;
  border-radius: 50%;
  background-color: #d4d4d4;
}

.names {
  display: flex;
  flex-direction: column;
}

.names > span:first-of-type {
  font: var(--text-body-m);
  line-height: 12px;
}

.names > span:last-of-type {
  font: var(--text-body-s);
  color: var(--color-content-6);
}

.plan {
  font: var(--text-body-m);
  line-height: 12px;
}

.nav {
  composes: box;
  gap: 16px;
  padding: var(--size-padding-m);
}

.navItem {
  display: flex;
  gap: 8px;
  padding: var(--size-padding-xs);
  text-decoration: none;
}

.body {
  overflow-y: auto;
  padding: 0 var(--size-padding-l);
  padding-top: 115px;
}

.helpTip {
  color: white;
}

.dismissHelpTip {
  margin-top: 16px;
  font-weight: bold;
  color: white;
}
