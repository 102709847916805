.trigger {
  display: inline-flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  border-radius: var(--size-radius-s);
  padding: 0 var(--size-padding-l);
  font-size: 16px;
  line-height: 1;
  gap: 12px;
  background-color: var(--color-surface-1);
  color: #000;
}

.small {
  composes: trigger;
  height: var(--size-action-small);
}

.medium {
  composes: trigger;
  height: var(--size-action);
}

.trigger:hover {
  background-color: var(--color-surface-2);
}

.error {
  border: 2px solid var(--color-content-error);
  background-color: var(--color-content-error-light);
}

.error:hover {
  background-color: var(--color-content-error-light);
}

.content {
  overflow: hidden;
  border-radius: var(--size-radius-m);
  background-color: white;
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.viewport {
  padding: var(--size-padding-s);
}

.item {
  line-height: 1;
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 var(--size-padding-xxl) 0 var(--size-padding-xl);
  font-size: 14px;
  border-radius: var(--size-radius-s);
  position: relative;
  user-select: none;
  color: var(--color-content-1);
}

.item.action {
  width: 100%;
  margin-top: 8px;
  border: 1px solid rgb(190, 190, 190);
}

.item.action:hover {
  background-color: var(--color-surface-4);
}

.item[data-disabled] {
  color: lightgray;
  pointer-events: none;
}

.item:focus {
  background-color: var(--color-surface-4);
}

.label {
  padding: 0 var(--size-padding-xl);
  font-size: 14px;
  font-weight: 500;
  line-height: 25px;
  color: var(--color-content-5);
}

.separator {
  height: 1px;
  margin: 5px;
  background-color: lightgray;
}

.itemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.scrollButton {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 25px;
  background-color: white;
  color: gray;
  cursor: default;
}
