.head {
  display: flex;
  align-items: flex-end;
  gap: 42px;
}

.head > div {
  flex-basis: 50%;
}

.title {
  font: var(--text-heading-xs);
  margin-bottom: 16px;
}

.description {
  margin-bottom: 24px;
  font: var(--text-body-m);
  color: var(--color-content-6);
}

.list {
  all: unset;
  display: block;
}

.list li {
  all: unset;
  display: block;
}

.list li + li {
  margin-top: 12px;
}

.headers {
  display: flex;
  align-items: flex-end;
  gap: 16px;
  margin-bottom: 24px;
}

.headers > div {
  flex: 1;
}

.header {
  display: inline-block;
  padding: var(--size-padding-s) var(--size-padding-l) var(--size-padding-s)
    var(--size-padding-xxl);
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.06);
  border-radius: var(--size-radius-m);
  text-transform: uppercase;
  background-color: #ffffff;
  background-repeat: no-repeat;
  background-position: left 12px center;
  background-size: 16px;
}

.original {
  composes: header;
  background-image: url('../../ui/images/webflow.svg');
}

.translated {
  composes: header;
  background-image: url('../../ui/images/comment.svg');
}

.translationsInfo {
  margin-bottom: 24px;
}

.twoColumns {
  display: flex;
  gap: 42px;
}

.twoColumns > div {
  flex-basis: 50%;
}

.search {
  display: flex;
  align-items: center;
  gap: 8px;
  margin-bottom: 16px;
  width: fit-content;
  min-width: 250px;
  position: relative;
  font: var(--text-body-s);
}

.search > button {
  position: absolute;
  top: 4px;
  right: 4px;
}

.search > input {
  border-radius: 24px;
}

.search > input::placeholder {
  font-size: 14px;
}
