@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: 'translateY(2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateY(0)';
  }
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: 'translateX(-2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateX(0)';
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: 'translateY(-2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateY(0)';
  }
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: 'translateX(2px)';
  }
  100% {
    opacity: 1;
    transform: 'translateX(0)';
  }
}

.content {
  padding: var(--size-padding-l);
  max-width: 400px;
  border-radius: var(--size-radius-s);
  font-size: 14px;
  line-height: 18px;
  color: #fff;
  background-color: var(--color-content-secondary);
  box-shadow: hsl(206 22% 7% / 35%) 0px 10px 38px -10px,
    hsl(206 22% 7% / 20%) 0px 10px 20px -15px;
}

@media (prefers-reduced-motion: no-preference) {
  .content {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    will-change: transform, opacity;
  }

  .content[data-state='delayed-open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  .content[data-state='delayed-open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }

  .content[data-state='delayed-open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }

  .content[data-state='delayed-open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.arrow {
  fill: var(--color-content-secondary);
}
