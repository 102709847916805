.root {
  max-width: 1064px;
  min-height: 100vh;
  display: flex;
  margin: auto;
}

.root > div:first-of-type {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.root > div:last-of-type {
  flex-grow: 1;
}

@media (max-width: 850px) {
  .root {
    margin-top: 30px;
  }

  .root > div:first-of-type {
    display: none;
  }
}

.logo {
  height: 36px;
  position: absolute;
  left: 24px;
  top: 24px;
}

/* .logo::after {
  content: 'BETA';
  display: block;
  margin-left: 102%;
  margin-top: -42px;
  font-size: 14px;
} */

.logo > svg {
  height: 100%;
  width: auto;
}

.contents {
  display: flex;
  justify-content: center;
  align-items: center;
}
