html {
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  scroll-behavior: smooth;
}

*,
*::before,
*::after {
  box-sizing: border-box;
}

::-moz-selection {
  color: white;
  background: var(--color-content-primary);
}

::selection {
  color: white;
  background: var(--color-content-primary);
}

* {
  margin: 0;
  padding: 0;
  border: 0;
  appearance: none;
  color: inherit;
  font: inherit;
}

button {
  background-color: unset;
  cursor: pointer;
}

ul {
  list-style-type: none;
}

strong {
  font-weight: 500;
}
