:root {
  --full-height: 100vh;
  --size-radius-s: 4px;
  --size-radius-m: 8px;
  --size-radius-l: 12px;
  --size-radius-xl: 16px;
  --size-padding-xs: 4px;
  --size-padding-s: 8px;
  --size-padding-m: 12px;
  --size-padding-l: 16px;
  --size-padding-xl: 24px;
  --size-padding-xxl: 32px;
  --size-action-small: 32px;
  --size-action: 40px;
  --size-unit-0: 8px;
  --size-0: 0;
  --size-1: 4px;
  --size-2: 8px;
  --size-3: 12px;
  --size-4: 16px;
  --size-5: 20px;
  --size-6: 24px;
  --size-7: 32px;
  --size-8: 40px;
  --size-9: 64px;
  --size-10: 80px;
  --size-11: 96px;
  --size-12: 144px;
}
