/* Original file: "@pathofdev/react-tag-input/build/index.css" */

.react-tag-input {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  box-sizing: border-box;
  width: 100%;
  min-height: var(--size-action);
  padding: var(--size-padding-s) var(--size-padding-l);
  margin: 0;
  position: relative;
  overflow-y: auto;
  border: none;
  outline: none;
  font: var(--text-body-m);
  color: var(--color-content-1);
  border-radius: var(--size-radius-l);
  background-color: var(--color-surface-2);
  transition: color var(--time-1) ease-out,
    background-color var(--time-1) ease-out;
}

.react-tag-input * {
  box-sizing: border-box;
}

.react-tag-input > * {
  margin: 0.1875em;
}

.react-tag-input__input {
  flex-basis: 48px;
  flex-grow: 1;
  min-width: 48px;
  height: 1.875em;
  padding: 0 0 0 0.1875em;
  margin: 0 0.1875em;
  font-size: 0.85em;
  line-height: 1;
  background: transparent;
  color: #333;
  border: none;
  border-radius: var(--size-radius-s);
  outline: 0;
  box-shadow: none;
  -webkit-appearance: none;
}

.react-tag-input__input::placeholder,
.react-tag-input__input:-moz-placeholder,
.react-tag-input__input:-ms-input-placeholder,
.react-tag-input__input::-moz-placeholder,
.react-tag-input__input::-webkit-input-placeholder {
  color: #333;
}

.react-tag-input__input:focus {
  border: none;
}

.react-tag-input__tag {
  position: relative;
  display: flex;
  align-items: center;
  font-size: 0.85em;
  line-height: 1;
  background-color: #bbbbbb;
  border-radius: var(--size-radius-s);
}

.reorderOnClick .react-tag-input__tag:first-of-type {
  background-color: var(--color-content-primary);
}

.react-tag-input__tag__content {
  outline: 0;
  border: none;
  white-space: nowrap;
  padding: 0 0.46875em;
}

.react-tag-input__tag__remove {
  position: relative;
  height: 2em;
  width: 2em;
  font-size: 0.85em;
  cursor: pointer;
  border-top-right-radius: var(--size-radius-s);
  border-bottom-right-radius: var(--size-radius-s);
}

.react-tag-input__tag__remove:before,
.react-tag-input__tag__remove:after {
  position: absolute;
  top: 50%;
  left: 50%;
  content: ' ';
  height: 0.9em;
  width: 0.15em;
  background-color: #333;
}

.react-tag-input__tag__remove:before {
  transform: translateX(-50%) translateY(-50%) rotate(45deg);
}

.react-tag-input__tag__remove:after {
  transform: translateX(-50%) translateY(-50%) rotate(-45deg);
}

.react-tag-input__tag__remove-readonly {
  width: 0;
}

.react-tag-input__tag__remove-readonly:before,
.react-tag-input__tag__remove-readonly:after {
  content: '';
  width: 0;
}

.react-tag-input__error > div {
  border: 2px solid var(--color-content-error);
  background-color: #f7cece;
}
