.tooltipWrapper {
  position: relative;
}

.tooltipWrapper:hover > .tooltip {
  visibility: visible;
  opacity: 1;
}

.tooltip {
  --tooltip-color: var(--color-content-secondary);
  visibility: hidden;
  width: var(--tooltip-width);
  margin-left: calc(var(--tooltip-width) / 2 * -1);
  text-align: center;
  border-radius: var(--size-radius-s);
  padding: var(--size-padding-s);
  position: absolute;
  z-index: 1;
  left: 50%;
  font-size: 14px;
  opacity: 0;
  color: #fff;
  background-color: var(--tooltip-color);
  transition: opacity 0.3s;
}

.top {
  bottom: calc(100% + var(--tooltip-offset, 10px));
}

.bottom {
  top: calc(100% + var(--tooltip-offset, 10px));
}

.top-left {
  bottom: calc(100% + var(--tooltip-offset, 10px));
  left: -210%;
}

/* Arrow */

.tooltip::after {
  content: '';
  position: absolute;
  margin-left: -5px;
  border-width: 5px;
  border-style: solid;
}

.top::after {
  top: 100%;
  left: 50%;
  border-color: var(--tooltip-color) transparent transparent transparent;
}

.top-left::after {
  top: 100%;
  left: 90%;
  border-color: var(--tooltip-color) transparent transparent transparent;
}

.bottom::after {
  bottom: 100%;
  left: 50%;
  border-color: transparent transparent var(--tooltip-color) transparent;
}
