.preview {
  padding-right: 24px;
  font: var(--text-body-m);
  text-decoration: none;
  background: transparent url('../../ui/images/external.svg') no-repeat right;
  background-size: 16px;
  color: var(--color-content-accent);
}

.placeholder {
  color: var(--color-content-accent);
}

.skeleton {
  border-radius: 8px;
  background-color: rgb(227, 227, 227);
  animation: 1.5s ease-in-out 0.5s infinite normal none running
    animation-skeleton;
}

.skeleton > * {
  opacity: 0;
}

@keyframes animation-skeleton {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 0.4;
  }
  100% {
    opacity: 1;
  }
}
