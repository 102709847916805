.progress {
  height: 8px;
  flex-grow: 1;
  border-radius: var(--size-radius-m);
  background-color: rgba(206, 208, 208, 1);
}

.progress::after {
  content: '';
  display: block;
  background-color: #7d2bd8;
  width: var(--progress-width);
  height: 100%;
  border-radius: var(--size-radius-m);
}
