.text {
  margin-top: 8px;
  font: var(--text-body-m);
  color: white;
}

.skip {
  position: absolute;
  right: 6px;
  top: 6px;
  color: #000;
}

.buttons {
  display: flex;
  margin-top: 16px;
  color: white;
}

.buttons > button {
  outline: none;
}

.spaced {
  justify-content: space-between;
}

.end {
  justify-content: flex-end;
}

.buttons > button:last-child {
  font: var(--text-body-m-bold);
}
