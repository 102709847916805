.content {
  min-width: 220px;
  background-color: white;
  border-radius: var(--size-radius-m);
  box-shadow: 0px 10px 38px -10px rgba(22, 23, 24, 0.35),
    0px 10px 20px -15px rgba(22, 23, 24, 0.2);
}

.padding {
  padding: var(--size-padding-xs);
}

@keyframes slideUpAndFade {
  0% {
    opacity: 0;
    transform: translateY(2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideRightAndFade {
  0% {
    opacity: 0;
    transform: translateX(-2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@keyframes slideDownAndFade {
  0% {
    opacity: 0;
    transform: translateY(-2px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes slideLeftAndFade {
  0% {
    opacity: 0;
    transform: translateX(2px);
  }
  100% {
    opacity: 1;
    transform: translateX(0);
  }
}

@media (prefers-reduced-motion: no-preference) {
  .content {
    animation-duration: 400ms;
    animation-timing-function: cubic-bezier(0.16, 1, 0.3, 1);
    animation-fill-mode: forwards;
    will-change: transform, opacity;
  }

  .content[data-state='open'][data-side='top'] {
    animation-name: slideDownAndFade;
  }

  .content[data-state='open'][data-side='right'] {
    animation-name: slideLeftAndFade;
  }
  .content[data-state='open'][data-side='bottom'] {
    animation-name: slideUpAndFade;
  }
  .content[data-state='open'][data-side='left'] {
    animation-name: slideRightAndFade;
  }
}

.item {
  all: unset;
  font-size: 13px;
  line-height: 1;
  color: black;
  border-radius: var(--size-radius-s);
  display: flex;
  align-items: center;
  height: 25px;
  padding: 0 var(--size-padding-xs);
  position: relative;
  padding-left: 25px;
  user-select: none;
}

.item[data-disabled] {
  color: lightgray;
  pointer-events: none;
}

.item:focus {
  background-color: gray;
  color: white;
}

.item:focus > .rightSlot {
  color: white;
}

.item[data-disabled] .rightSlot {
  color: lightgray;
}

.checkboxItem {
  composes: item;
}

.radioItem {
  composes: item;
}

.triggerItem {
  composes: item;
}

.triggerItem[data-state='open'] {
  background-color: gray;
  color: white;
}

.label {
  padding-left: 25px;
  font-size: 12px;
  line-height: 25px;
  color: gray;
}

.separator {
  height: 1px;
  background-color: lightgray;
  margin: 5px;
}

.itemIndicator {
  position: absolute;
  left: 0;
  width: 25px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
}

.arrow {
  fill: white;
}

.rightSlot {
  margin-left: auto;
  padding-left: 20px;
  color: gray;
}
