:root {
  --text-tag: 500 14px/20px 'Inter', sans-serif;

  --text-body-xs: 400 12px/16px 'Inter', sans-serif;
  --text-body-s: 400 14px/20px 'Inter', sans-serif;
  --text-body-m: 400 16px/24px 'Inter', sans-serif;
  --text-body-m-bold: 500 16px/24px 'Inter', sans-serif;
  --text-body-l: 400 20px/28px 'Inter', sans-serif;

  --text-heading-xxs: 500 16px/24px 'Inter', sans-serif;
  --text-heading-xs: 500 24px/32px 'Inter', sans-serif;
  --text-heading-s: 500 36px/42px 'Inter', sans-serif;
  --text-heading-m: 500 44px/52px 'Inter', sans-serif;
  --text-heading-l: 500 62px/68px 'Inter', sans-serif;
}
