.placeholder {
  display: flex;
  gap: 16px;
}

.placeholder::before,
.placeholder::after {
  content: '';
  display: block;
  flex: 1;
  height: 64px;
  overflow: hidden;
  border-radius: var(--size-radius-l);
  background-color: #f0f0f0;
  background-image: linear-gradient(
    100deg,
    rgba(255, 255, 255, 0),
    rgba(255, 255, 255, 0.25) 50%,
    rgba(255, 255, 255, 0) 80%
  );
  background-repeat: no-repeat;
  background-size: 32px 100%;
  background-position: -32px 0;
  animation: shine 2s infinite;
}

@keyframes shine {
  to {
    background-position: calc(100% + 32px) 0;
  }
}
