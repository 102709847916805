.editor {
  color: #666666;
  font: var(--text-body-m);
}

.editor :global(.ce-block):first-child > div > * {
  padding-top: 0 !important;
}

.editor :global(.ce-block):last-child > div > * {
  margin-bottom: 0 !important;
}

.editor h1 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-m);
}

.editor h2 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-s);
}

.editor h3 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-heading-xs);
}

.editor h4 {
  color: #000000;
  padding: 1em 0 0.5em 0;
  font: var(--text-body-m-bold);
}

.editor strong,
.editor b {
  color: #000000;
  font-weight: 600;
}

.blockquote {
  padding: 1em;
  border-left: 4px solid currentColor;
}
