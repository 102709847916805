.cardFormInputs {
  margin-top: 8px;
  margin-bottom: 16px;
}

.cardFormInput {
  padding: var(--size-padding-m);
  border-radius: var(--size-radius-s);
  background-color: #f3f3f3;
}
