.paymentCard {
  padding: var(--size-padding-xl);
  border-radius: var(--size-radius-l);
  border: 1px solid #cccccc;
}

.paymentCard > p + p {
  margin-top: 8px;
}

.paymentCard span {
  font-weight: 500;
}
