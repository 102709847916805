.card {
  padding: var(--size-padding-l);
  border-radius: var(--size-radius-l);
  background-color: #fff;
  box-shadow: 0 20px 20px rgba(0, 0, 0, 0.06);
}

.header {
  display: flex;
  margin-bottom: var(--size-5);
}

.title {
  flex-grow: 1;
  font: var(--text-body-l);
  border-radius: var(--size-radius-m);
}

.delete {
  margin-left: 16px;
  background-color: unset;
  cursor: pointer;
  opacity: 0;
  color: var(--color-content-4);
  transition: opacity 0.2s;
}

.card:hover .delete {
  opacity: 1;
}

.delete > svg {
  width: 20px;
}

.imageWrapper {
  position: relative;
}

.imageWrapper > img {
  display: block;
  width: 100%;
  height: 170px;
  object-fit: cover;
}

.placeholder {
  background-color: #e8e8e8;
  width: 100%;
  height: 170px;
}

.manageButton {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  font: var(--text-heading-xs);
  opacity: 0;
  color: #fff;
  background-color: rgba(0, 0, 0, 0.6);
  transition: opacity 0.3s ease;
  cursor: pointer;
}

.manageButton:hover {
  opacity: 1;
}
